import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Icon from "../components/icon.tsx";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import ProductsToc from "../components/products-toc";
import Breadcrumbs from "../components/breadcrumbs"
import Out, { OutProductRedirect } from "../components/out-product";
import useDescription from "../hooks/useDescription";
import Translate from "../components/translate"
import AdvDis from "../components/adv-dis";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import resolveProductUrl from "../utils/urls/resolve-product-url";
import Layout from "../components/layout";
import SimilarProducts from "../components/similar-products";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}  description={props.data?.mysqlApps?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/app/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const BankApp = ({
  data: { mysqlApps, faq, allMysqlComments, similarAccounts, allMysqlDescriptionApp, mysqlAuthor },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const [isOpenToc, setIsOpenToc] = useState(false);
  const {
    transfers_rating,
    rating,
    possibilities_rating,
    name,
    login_rating,
    mysqlId,
    distinctions_rating,
    google_play_rating,
    disadvantages,
    image,
    advantages,
    mysqlImages,
    bank,
    app_store_rating,
    appgalerry_rating,
  } = mysqlApps;

  const d = useDescription(allMysqlDescriptionApp.nodes);

  let idTimeout = null;
  const scroll = (id) => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(id);

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={["bank-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")} />
      <div className={[b.container, 'breadcrumbs-mobile-2', b.py3, b.ptLg5, b.positionRelative, 'breadcrumbs-zIndex', "scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={["firm-page", 'account-header'].join(' ')}>
        <div className={["local-firm-page", "blog-content", "post-content", b.positionRelative, 'account-rys'].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <div className={[b.dFlex, b.mt3, b.alignItemsCenter].join(" ")}>
                  <div className={["icon-box-acc"].join(" ")}>
                    <Icon size={40} icon="Cell-phone" />
                  </div>
                  <h1 className={["big-title", b.pl3, b.mb0].join(" ")}>
                    {name}
                  </h1>
                </div>
              </div>
              
              <div className={[b.colLg4,b.mbLg5,b.pbLg5, b.orderLgLast].join(' ')}>
                {mysqlImages && (
                  <>
                    <div className={["bank-box", "padding-bottom", "sticky-box",].join(" ")}>
                      <div className={[b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.mt3, b.m4].join(' ')}>
                        <div className={['apps-stores-img', 'dark-mode-image cursor-pointer', b.p2, b.px3].join(' ')} onClick={OutProductRedirect(mysqlApps)}>
                          <Thumbnail image={mysqlImages[0]} alt={name} />
                        </div>
                      </div>
                      <div className={[b.dFlex, b.justifyContentBetween, b.alignItemsCenter].join(' ')}>
                        <p className={['font-size-13', b.my0, b.py0].join(' ')}>Google Play</p>
                        <div className={b.dFlex}>
                          <div className={[b.dFlex, b.pb2, b.justifyContentCenter,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                            <div className="stars-container">
                              {google_play_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${google_play_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                          </div>
                          <p className={['font-size-13', b.my0, b.pt1, b.textCenter, b.ml2].join(' ')}>
                            {(google_play_rating > 0 ? google_play_rating / 20 : 0).toFixed(1)}
                          </p>
                        </div>
                      </div>

                      <div className={[b.dFlex, b.justifyContentBetween, b.alignItemsCenter].join(' ')}>
                        <p className={['font-size-13', b.my0, b.py0].join(' ')}>App Store</p>
                        <div className={b.dFlex}>
                          <div className={[b.dFlex, b.pb2, b.justifyContentCenter,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                            <div className="stars-container">
                              {app_store_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${app_store_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                          </div>
                          <p className={['font-size-13', b.my0, b.pt1, b.textCenter, b.ml2].join(' ')}>{(app_store_rating / 20).toFixed(1)}</p>
                        </div>
                      </div>
                      <div className={[b.dFlex, b.justifyContentBetween, b.alignItemsCenter].join(' ')}>
                        <p className={['font-size-13', b.my0, b.py0].join(' ')}>AppGallery</p>
                        <div className={b.dFlex}>
                          <div className={[b.dFlex, b.pb2, b.justifyContentCenter,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                            <div className="stars-container">
                              {appgalerry_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${appgalerry_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                          </div>
                          <p className={['font-size-13', b.my0, b.pt1, b.textCenter, b.ml2].join(' ')}>{(appgalerry_rating / 20).toFixed(1)}</p>
                        </div>
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                        <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(" ")} item={mysqlApps.bank} defaultDescription="Create account" defaultIcon="Taking-money" />
                      <div className={[b.dFlex, b.justifyContentCenter, b.textCenter, "blueToWhite"].join(" ")}>
                        <div className={b.w100}>
                          <button className={["btn-hidden", "blueToWhite", 'iconToWhite', b.mt1, "margin-1",].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? (
                              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                            ) : ("")}
                            {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                          </button>
                          {isOpenToc ? (
                            <ProductsToc selector=".firm-page .blog-content h2[id]" />
                          ) : ("")}

                          <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                            <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite',].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                              {!isOpenToc ? ("") : (
                                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                              )}
                              {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {bank && bank.name && (
                  <div className={"local-firm-box-1"}>
                    <h3><Translate tKey="Products" /> {bank.name}</h3>
                    {bank.credits.length > 0 && (
                      bank.credits.map(({ type, name, ...credit }, i) => (
                        <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                          <Link to={resolveProductUrl({ bank, ...credit })}>
                            <div className={[b.dFlex, b.mr3].join(" ")}>
                              {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 7 ? <Icon size={40} icon="Premium-acc" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                            </div>
                          </Link>
                          <Out item={credit} withoutText />
                        </div>
                      ))
                    )}
                    {bank.card && (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')}>
                        <Link to={resolveProductUrl({ bank, ...bank.card })}>
                          <div className={[b.dFlex, b.mr3].join(" ")}>
                            <Icon size={40} icon="Credit-cards" className={[b.mr2, "icon-blog"].join(" ")} />
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{bank.card.name}</p>
                          </div>
                        </Link>
                        <Out item={bank.card} withoutText />
                      </div>
                    )}
                    {bank.accounts.length > 0 && (
                      bank.accounts.map(({ type, name, ...account }, i) => (
                        <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                          <Link to={resolveProductUrl({ bank, ...account })}>
                            <div className={[b.dFlex, b.mr3].join(' ')}>
                              {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 7 ? <Icon size={40} icon="Premium-acc" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                            </div>
                          </Link>
                          <Out item={account} withoutText />
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
              <div className={b.colLg8}>
                <div className={["bank-box", b.mt4].join(" ")}>
                  <div className={b.row}>
                    <div className={[b.colLg7, b.mbLg2, b.mb0, 'text-center-mobile'].join(" ")}>
                      <span className='font-size-20'>Nasza ocena aplikacji</span>
                    </div>
                    <div className={[b.colLg5, b.dFlex, b.justifyContentEnd, b.mb2, 'justify-center-mobile'].join(" ")}>
                      <p className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", b.mb0].join(" ")}>
                        {rating > 0 ? `${(rating / 20).toFixed(1)}/5` : 0}
                      </p>
                      <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.ml2].join(" ")}>
                        <div className="stars-container">
                          {rating > 0 && (
                            <div className="star-box" style={{ width: `${rating}%` }}>
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" />
                            </div>
                          )}
                          <div>
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={b.colLg3}>
                      <p className={['product-mobile-fs2', b.pb0, b.mb1, "blackToWhite", 'text-center-mobile'].join(' ')}>Logowanie</p>
                      <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                        <div className="stars-container">
                          {login_rating > 0 && (
                            <div className="star-box-apps" style={{ width: `${login_rating}%` }}>
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" />
                            </div>
                          )}
                          <div>
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" />
                          </div>
                        </div>
                        <p className={["blackToWhite", b.mb0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                          {(login_rating > 0 ? login_rating / 20 : 0).toFixed(1)}
                        </p>
                      </div>
                    </div>
                    <div className={b.colLg3}>
                      <p className={['product-mobile-fs2', b.pb0, b.mb1, b.pt3, b.ptLg0, "blackToWhite", 'text-center-mobile'].join(' ')}>Przelewy</p>
                      <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                        <div className="stars-container">
                          {transfers_rating > 0 && (
                            <div className="star-box-apps" style={{ width: `${transfers_rating}%` }}>
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" />
                            </div>
                          )}
                          <div>
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" />
                          </div>
                        </div>
                        <p className={["blackToWhite", b.mb0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                          {(transfers_rating > 0 ? transfers_rating / 20 : 0).toFixed(1)}
                        </p>
                      </div>
                    </div>
                    <div className={b.colLg3}>
                      <p className={['product-mobile-fs2', b.pb0, b.mb1, "blackToWhite", 'text-center-mobile', b.pt3, b.ptLg0].join(' ')}>Możliwości</p>
                      <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                        <div className="stars-container">
                          {possibilities_rating > 0 && (
                            <div className="star-box-apps" style={{ width: `${possibilities_rating}%` }}>
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" />
                            </div>
                          )}
                          <div>
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" />
                          </div>
                        </div>
                        <p className={["blackToWhite", b.mb0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                          {(possibilities_rating > 0 ? possibilities_rating / 20 : 0).toFixed(1)}
                        </p>
                      </div>
                    </div>
                    <div className={b.colLg3}>
                      <p className={['product-mobile-fs2', b.pb0, b.mb1, "blackToWhite", 'text-center-mobile', b.pt3, b.ptLg0].join(' ')}>Innowacyjność</p>
                      <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                        <div className="stars-container">
                          {distinctions_rating > 0 && (
                            <div className="star-box-apps" style={{ width: `${distinctions_rating}%` }}>
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" className={b.mr1} />
                              <Icon size={16} icon="Star" />
                            </div>
                          )}
                          <div>
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" className={b.mr1} />
                            <Icon size={16} icon="Empty-star" />
                          </div>
                        </div>
                        <p className={["blackToWhite", b.mb0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                          {(distinctions_rating > 0 ? distinctions_rating / 20 : 0).toFixed(1)}
                        </p>
                      </div>
                    </div>
                    {mysqlAuthor && mysqlAuthor.mysqlImage && 
                      <div className={[b.mt3,b.alignItemsCenter,b.justifyContentCenter,'bankApp-dFlex',b.colLg3].join(' ')}>
                        <div className={["blog-author-text", "blog-header"].join(" ")}>
                          <div className={["blog-author", b.dFlex, b.alignItemsCenter,'blackToWhite'].join(" ")}>
                            <Thumbnail image={mysqlAuthor.mysqlImage} className="bankApp-author" alt={mysqlAuthor.name}/>
                            <div className={[b.dBlock,b.ml2].join(' ')}>
                              <span className={['font-size-10','blackToWhite'].join(' ')}>Autor recenzji</span>
                              <div className={["blog-author-text",'tp-name','font-size-13'].join(" ")}>
                                {mysqlAuthor.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <div className={["blueToGray", 'dark-li', b.mt3, b.mtLg5].join(' ')}>
                  {d?.register?.header && d?.register?.description && (<>
                    <h2 id="section-title-0">
                      {d.register.header}
                    </h2>
                    <BlogContent content={d.register.description} />
                  </>)}

                  {d?.screen?.header && d?.screen?.description && (<>
                    <h2 id="section-title-1">
                      {d.screen.header}
                    </h2>
                    <BlogContent content={d.screen.description} />
                  </>)}

                  {d?.login?.header && d?.login?.description && (<>
                    <h2 id="section-title-2">
                      {d.login.header}
                    </h2>
                    <BlogContent content={d.login.description} />
                  </>)}

                  {d?.allows?.header && d?.allows?.description && (<>
                    <h2 id="section-title-3">
                      {d.allows.header}
                    </h2>
                    <BlogContent content={d.allows.description} />
                  </>)}

                  {d?.security?.header && d?.security?.description && (<>
                    <h2 id="section-title-4">
                      {d.security.header}
                    </h2>
                    <BlogContent content={d.security.description} />
                  </>)}

                  {d?.problems?.header && d?.problems?.description && (<>
                    <h2 id="section-title-5">
                      {d.problems.header}
                    </h2>
                    <BlogContent content={d.problems.description} />
                  </>)}

                  {d?.installation?.header && d?.installation?.description && (<>
                    <h2 id="section-title-6">
                      {d.installation.header}
                    </h2>
                    <BlogContent content={d.installation.description} />
                  </>)}

                  {advantages && disadvantages && (<>
                    {d?.advantages?.description && <BlogContent content={d.advantages.description} />}

                    <AdvDis advantages={advantages} disadvantages={disadvantages}>
                      <h2 id="section-title-7">{d?.advantages?.header || <>Wady i zalety {name}</>}</h2>
                    </AdvDis>
                  </>)}

                  {d?.summary.header && d?.summary.description && (<>
                    <h2 id="section-title-8">{d.summary.header}</h2>
                    <BlogContent content={d.summary.description} />
                  </>)}

                  <div className={['home-page-white-box', b.p3, b.mt3].join(' ')}>
                    <div className={[b.row].join(' ')}>
                      <div className={[b.colLg4, b.justifyContentCenter, b.alignItemsCenter, b.textCenter, 'rating-box'].join(' ')}>
                        <div className={[b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(' ')}>
                          <div className={['apps-stores-img', 'dark-mode-image', b.p2, b.px3].join(' ')}>
                            <Thumbnail image={mysqlImages[0]} alt={name} />
                          </div>
                        </div>
                        <p className={['product-mobile-fs2', "blackToWhite", b.my1].join(' ')}>Ocena na Google Play</p>
                        <div className={[b.justifyContentCenter, b.dFlex, b.mb2].join(' ')}>
                          <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                            <div className="stars-container">
                              {google_play_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${google_play_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                            <p className={["blackToWhite", b.m0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                              {google_play_rating > 0 ? google_play_rating / 20 : 0}
                            </p>
                          </div>
                        </div>
                        <span className={["blackToWhite", b.mb2, 'font-size-13'].join(' ')}>Pobierz aplikację</span>
                        <img src="/assets/apps-images/google_play.svg" alt="Google Play" className={b.mt1} loading="lazy" />
                      </div>

                      <div className={[b.colLg4, b.justifyContentCenter, b.alignItemsCenter, b.textCenter, 'rating-box', b.mt5, b.mtLg0].join(' ')}>
                        <div className={[b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(' ')}>
                          <div className={['apps-stores-img', 'dark-mode-image', b.p2, b.px3].join(' ')}>
                            <Thumbnail image={mysqlImages[0]} alt={name} />
                          </div>
                        </div>
                        <p className={['product-mobile-fs2', "blackToWhite", b.my1].join(' ')}>Ocena w App Store</p>
                        <div className={[b.justifyContentCenter, b.dFlex, b.mb2].join(' ')}>
                          <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                            <div className="stars-container">
                              {app_store_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${app_store_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                            <p className={["blackToWhite", b.m0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                              {(app_store_rating / 20).toFixed(1)}
                            </p>
                          </div>
                        </div>
                        <span className={["blackToWhite", b.mb2, 'font-size-13'].join(' ')}>Pobierz aplikację</span>
                        <img src="/assets/apps-images/app_store.svg" alt="App store" className={b.mt1} loading="lazy" />
                      </div>

                      <div className={[b.colLg4, b.justifyContentCenter, b.alignItemsCenter, b.textCenter, 'rating-box', b.mt5, b.mb2, b.myLg0].join(' ')}>
                        <div className={[b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(' ')}>
                          <div className={['apps-stores-img', 'dark-mode-image', b.p2, b.px3].join(' ')}>
                            <Thumbnail image={mysqlImages[0]} alt={name} />
                          </div>
                        </div>
                        <p className={['product-mobile-fs2', "blackToWhite", b.my1].join(' ')}>Ocena w AppGallery</p>
                        <div className={[b.justifyContentCenter, b.dFlex, b.mb2].join(' ')}>
                          <div className={['apps-rating-wrapper', b.dFlex, b.px3, b.py1, b.justifyContentCenter].join(" ")}>
                            <div className="stars-container">
                              {appgalerry_rating > 0 && (
                                <div className="star-box-apps" style={{ width: `${appgalerry_rating}%` }}>
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" className={b.mr1} />
                                  <Icon size={16} icon="Star" />
                                </div>
                              )}
                              <div>
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" className={b.mr1} />
                                <Icon size={16} icon="Empty-star" />
                              </div>
                            </div>
                            <p className={["blackToWhite", b.m0, 'font-size-13', b.pb0, b.dFlex, b.alignItemsCenter, b.ml2, b.pt1].join(" ")}>
                              {(appgalerry_rating > 0 ? appgalerry_rating / 20 : 0).toFixed(1)}
                            </p>
                          </div>
                        </div>
                        <span className={["blackToWhite", 'font-size-13'].join(' ')}>Pobierz aplikację</span>
                        <img src="/assets/apps-images/app_gallery.svg" alt="AppGallery" className={b.mt1} loading="lazy" />
                      </div>
                    </div>
                  </div>

                  {faq?.nodes.length > 0 && (
                    <>
                      {d.faq.header && <h2 id="section-title-9">{d.faq.header}</h2>}
                      {d.faq.description && <BlogContent content={d.faq.description} className={b.mb3} />}
                      
                      {faq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}

                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                          <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                          <Translate tKey="Ask your own question" />
                        </button>
                      </div>
                    </>
                  )}
                  <Comments items={allMysqlComments.nodes} title="account" descHeader={d?.desc?.header || d?.opinions?.header} formID={mysqlId} commentType="account" />
                  <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="account" faq={isFaq} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {similarAccounts.edges.length > 0 && (<>
          <div className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
            <span className={["title", b.dBlock, b.mt3].join(' ')}>Inne produkty banku</span>
            <SimilarProducts items={similarAccounts} product="account" productType="accounts" />
          </div>
        </>)}
      </article>
    </Layout>
  );
};

export default BankApp


export const query = graphql`
  query QueryBankApps($mysqlId: Int!, $bank_id: Int!, $rating_author: Int!) {
    allMysqlDescriptionApp(filter: { app_id: { eq: $mysqlId }}) {
      nodes {
        description,
        header
        name
      }
    }

    mysqlApps(mysqlId: { eq: $mysqlId }) {
      transfers_rating
      rating
      possibilities_rating
      name
      login_rating
      ios_url
      mysqlId
      distinctions_rating
      google_play_rating
      disadvantages
      image
      description
      advantages
      android_url
      app_store_rating
      appgalerry_rating
      bank {
        name
        url
        prefix_url
        specific_url
        card {
          url
          prefix_url
          specific_url
          name
          wniosker_id
        }
        credits {
          name
          specific_url
          url
          prefix_url
          type
          wniosker_id
        }
        accounts {
          specific_url
          prefix_url
          url
          name
          type
          wniosker_id
        }
      }
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 200)
        }
      }
    }

    mysqlAuthor(mysqlId: {eq: $rating_author}) {
      mysqlImage {
        childImageSharp {
          gatsbyImageData(width: 44, height: 44)
        }
      }
      name
    }

    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "app" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }

    allMysqlComments(filter: {type: {eq: "app"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "app" }}) {
      nodes {
        ...SeoData
      }
    }

    similarAccounts: allMysqlAccount(filter: { bank_id: { eq: $bank_id } }, limit: 4) {
      edges {
        node {
          mysqlId
          name
          user_comments
          rating
          url
          specific_url
          prefix_url
          type
          wniosker_id
          bank {
            prefix_url
            specific_url
            url
          }
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 103)
            }
          }
        }
      }
    }
  }
`;
